<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ job.title }}</span>
    <div :class="`d-flex flex-column ${isWide ? 'm-30 font-25' : isMobile ? 'm-5 font-15' : 'm-10 font-20'}`">
      <p :class="isMobile ? 'font-15' : 'font-20'" v-html="job.content" />
      <div class="my-10 d-flex justify-content-center">
        <v-btn class="my-5 mx-auto button-normal">
          <a href="mailto:info@eventboxde.com" class="color-white">Apply Now</a>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .apply-button {
    float: right;
    font-family: 'theboldfont';
    color: white;
    vertical-align: middle;
  }
</style>

<script>
export default {
  name: 'JobDetail',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      jobs: [
        { // 1
          title: 'Country Manager',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>'+
' <li>Manage and grow post-sale client relationships with advertisers of all sizes.</li>' +
' <li>Coordinate with ad ops, product and R&D teams etc. internally to ensure a smooth and successful campaign execution.</li>' +
' <li>Work with internal teams to guide inventory allocation.</li>' +
' <li>Monitor and analyze ad campaigns, and proactively take initiative to improve results.</li>' +
' <li>Investigate and work directly with product & engineering teams to help them troubleshoot client issues during a campaign.</li>' +
' <li>Proficient in campaign management, setting up and optimization. Analyze campaign performance data to offer clients data-driven business insights and at the same time, use those insights to improve our advertising offerings.</li>' +
' <li>Share marketplace feedback and guide our advertising strategy.</li>' +
' <li>Support and work with sales team on pre-sale research and pitch.</li>' +
' <li>Conduct regular business reviews and take a consultative, long-term approach to build productive relationships with clients, in collaboration with the sales team.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>At least 3-5 years of experience in account management/client services roles in digital marketing, Digital agency experience is a big plus.</li>' +
'	<li>Experience managing digital performance advertisers with a deep understanding of their challenges.</li>' +
'	<li>Ability to analyze data and identify insights to assess campaign performance.</li>' +
'	<li>Strong in Ad optimization experience.</li>' +
'	<li>Proactive attitude with excellent project management, organizational, and analytical skills.</li>' +
'	<li>Proficient in English and Bahasa Indonesia.</li>' +
'</ul>'
        },
        { // 2
          title: 'Social Media Content Manager',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Develop, implement and manage our social media strategy.</li>' +
'	<li>Manage and oversee social media content to design the best viral contents.</li>' +
'	<li>Monitor SEO and user engagement to suggest content optimization.</li>' +
'	<li>In charge of daily operating and producing viral contents for most impactful social platforms in local market.</li>' +
'	<li>Communicate with internal and external parties to optimize user growth hacking methods.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Stay up to date with latest social media best practices and technologies.</li>' +
'	<li>Have good skill at social media marketing and viral trends.</li>' +
'	<li>Good video editing skill is a huge plus.</li>' +
'	<li>Fluent in English and Bahasa Melayu is a must, while have language skills in Madarin is a plus.</li>' +
'	<li>Can work at least 4 days per week.</li>' +
'</ul>'
        },
        { // 3
          title: 'Marketing Manager',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Develop & manage overall marketing strategy, implementation and optimization for TikTok in SEA region based on the deep understanding of Product, Operation and Marketing.</li>' +
'	<li>Coordinate and integrate the internal team and external partnership resources to invest into the overall marketing campaign and maximum the campaign power.</li>' +
'	<li>Evangelize partners internally and externally by delivering presentations at business meetings in front of internal teams, strategic partners and media.</li>' +
'	<li>Measure the performance marketing campaigns, gain insight to adjust strategy and tactics accordingly.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>5 years+ Experience in mar-tech companies or MNC digital advertising agencies, proven experience in identifying target audiences and creatively devising mutual-channel marketing campaigns.</li>' +
'	<li>Up-to-date with the latest trends and best practices in online marketing and influencer ecosystem in Vietnam market.</li>' +
'	<li>Excellent written and verbal communications in English.</li>' +
'	<li>Self-starter, fast learner with a can-do spirit.</li>' +
'	<li>Strong organizational and project management skills.</li>' +
'	<li>Strong in communication and a good team player with cross function team.</li>' +
'</ul>'
        },
        { // 4
          title: 'Creator & Content Strategies',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>You will be responsible for developing organic content strategies for eventbox partners, supporting clients agency partners in building their eventbox channels.</li>' +
'	<li>You will be responsible for managing eventbox Creators for client campaigns.</li>' +
'	<li>Monitoring content ecosystems on eventbox and competitor platforms and collaborate with the Data team to improve the diversity of partner content.</li>' +
'	<li>You will be facing off with external stakeholders sharing feedback & guidelines with partners to acquire specific content needed in the community.</li>' +
'	<li>Develop long term eventbox Content and Growth Strategy, whilst also presenting to clients and partners.</li>' +
'	<li>Support clients in executing eventbox Content strategy.</li>' +
'	<li>Research new market trends as well as user insights to leverage them into impactful marketing activities.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Proficient language skills in French and English.</li>' +
'	<li>Demonstrated experience in content, journalism, media, communications, marketing, or PR related role required.</li>' +
'	<li>Experience working with content creators or influencers.</li>' +
'	<li>Experience working in the fashion and beauty industry.</li>' +
'	<li>Deep passion for Social Media and strategy.</li>' +
'	<li>Strong understanding of content strategy and audience development.</li>' +
'	<li>Know how to drive the buzz/viral campaigns locally.</li>' +
'	<li>Interest for social issues, breaking news and popular trends in Europe, a good understanding of the pop culture , a sense for trends and virality.</li>' +
'	<li>A data-driven approach to working with content and community.</li>' +
'</ul>'
        },
        { // 5
          title: 'Marketing Manager',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Develop & manage overall marketing strategy, implementation and optimization for TikTok in SEA region based on the deep understanding of Product, Operation and Marketing.</li>' +
'	<li>Coordinate and integrate the internal team and external partnership resources to invest into the overall marketing campaign and maximum the campaign power.</li>' +
'	<li>Evangelize partners internally and externally by delivering presentations at business meetings in front of internal teams, strategic partners and media.</li>' +
'	<li>Measure the performance marketing campaigns, gain insight to adjust strategy and tactics accordingly.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>5 years+ Experience in mar-tech companies or MNC digital advertising agencies, proven experience in identifying target audiences and creatively devising mutual-channel marketing campaigns.</li>' +
'	<li>Up-to-date with the latest trends and best practices in online marketing and influencer ecosystem in Vietnam market.</li>' +
'	<li>Excellent written and verbal communications in English.</li>' +
'	<li>Self-starter, fast learner with a can-do spirit.</li>' +
'	<li>Strong organizational and project management skills.</li>' +
'	<li>Strong in communication and a good team player with cross function team.</li>' +
'</ul>'
        },
        { // 6
          title: 'Creator & Content Strategies',
          content: '<p><b>Responsibilities</p>' +
'<ul>' +
'	<li>You will be responsible for developing organic content strategies for eventbox partners, supporting clients agency partners in building their eventbox channels.</li>' +
'	<li>You will be responsible for managing eventbox Creators for client campaigns.</li>' +
'	<li>Monitoring content ecosystems on eventbox and competitor platforms and collaborate with the Data team to improve the diversity of partner content.</li>' +
'	<li>You will be facing off with external stakeholders sharing feedback & guidelines with partners to acquire specific content needed in the community.</li>' +
'	<li>Develop long term eventbox Content and Growth Strategy, whilst also presenting to clients and partners.</li>' +
'	<li>Support clients in executing eventbox Content strategy.</li>' +
'	<li>Research new market trends as well as user insights to leverage them into impactful marketing activities.</li>' +
'</ul>' +
'<br /><br />' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Proficient language skills in French and English.</li>' +
'	<li>Demonstrated experience in content, journalism, media, communications, marketing, or PR related role required.</li>' +
'	<li>Experience working with content creators or influencers.</li>' +
'	<li>Experience working in the fashion and beauty industry.</li>' +
'	<li>Deep passion for Social Media and strategy.</li>' +
'	<li>Strong understanding of content strategy and audience development.</li>' +
'	<li>Know how to drive the buzz/viral campaigns locally.</li>' +
'	<li>Interest for social issues, breaking news and popular trends in Europe, a good understanding of the pop culture , a sense for trends and virality.</li>' +
'	<li>A data-driven approach to working with content and community.</li>' +
'</ul>'
        },
        { // 7
          title: 'Partnership Manager',
          content: '<p>Client Relationship Management</p>' +
'<ul>' +
'	<li>Establish and manage client relationships with brands or agencies.</li>' +
'	<li>Drive deals to closure.</li>' +
'	<li>Retain and grow revenue from advertisers.</li>' +
'	<li>Provide clients with the highest level of sales and customer service.</li>' +
'	<li>Collaborate with internal teams globally across product, marketing, legal, engineering and sales.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Brainstorm, identify, and implement advertising solutions on ByteDance products to deliver measurable results for clients.</li>' +
'	<li>Serve as an external product consultant on properties that are owned and operated by ByteDance.</li>' +
'	<li>Analyze data and glean insights to execute the client strategy and ByteDance’s marketing solutions.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Campaign Planning and Management</b></p>' +
'<ul>' +
'	<li>Facilitate clients’ ad campaigns from start to finish.</li>' +
'	<li>Manage internal account operations including account set up etc.</li>' +
'	<li>Plan and build compelling storylines and data-driven presentations that are customized to clients’ business and problems.</li>' +
'</ul>' +
'<br/><br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>BA/BS degree in marketing, advertising, technology or media field.</li>' +
'	<li>More than three to five years of experiences in ad sales marketing and development, or a sales-related support function.</li>' +
'	<li>Demonstrated understanding of digital media platforms and ad metrics.</li>' +
'	<li>Demonstrated experience in working with cross-functional teams.</li>' +
'	<li>Excellent verbal and written English and Thai communication skills, Chinese as a plus.</li>' +
'	<li>Experience with analyzing data and delivering insights.</li>' +
'	<li>Self-starter, fast learner with a can-do spirit.</li>' +
'	<li>Ability to travel domestically and internationally as needed.</li>' +
'</ul>'
        },
        { // 8
          title: 'Product Manager',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Brainstorm, identify, and implement advertising solutions on ByteDance products to deliver measurable results for clients.</li>' +
'	<li>Serve as an external product consultant on properties that are owned and operated by ByteDance.</li>' +
'	<li>Analyze data and glean insights to execute the client strategy and ByteDance’s marketing solutions.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Campaign Planning and Management</b></p>' +
'<ul>' +
'	<li>Facilitate clients’ ad campaigns from start to finish.</li>' +
'	<li>Manage internal account operations including account set up etc.</li>' +
'	<li>Plan and build compelling storylines and data-driven presentations that are customized to clients’ business and problems.</li>' +
'</ul>' +
'<br/><br/></br>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>BA/BS degree in marketing, advertising, technology or media field.</li>' +
'	<li>More than three to five years of experiences in ad sales marketing and development, or a sales-related support function.</li>' +
'	<li>Demonstrated understanding of digital media platforms and ad metrics.</li>' +
'	<li>Demonstrated experience in working with cross-functional teams.</li>' +
'	<li>Excellent verbal and written English and Thai communication skills, Chinese as a plus.</li>' +
'	<li>Experience with analyzing data and delivering insights.</li>' +
'	<li>Self-starter, fast learner with a can-do spirit.</li>' +
'	<li>Ability to travel domestically and internationally as needed.</li>' +
'</ul>'
        },
        { // 9
          title: 'Art Director/Designer',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Lead and execute a wide range of high impact creative projects including but not limited to in-app campaigns, 360° branded events, short videos, gifs, video experiences on our 80ft lobby display.</li>' +
'	<li>Offer crisp direction and feedback with an artful eye.</li>' +
'	<li>Design in a way that aligns with, elevates and expands the eventbox brand.</li>' +
'	<li>Promote best practices and standards to develop and improve workflows.</li>' +
'	<li>Develop visual assets for both one-off and ongoing initiatives.</li>' +
'	<li>Package ideas into awesome presentations.</li>' +
'	<li>Work across teams and partners, synthesize briefs and feedback into creative visual design solutions.</li>' +
'	<li>Contribute to a positive, inclusive environment that values exploration and experimentation.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Bachelor\'s degree.</li>' +
'	<li>Exceptional design skills, including layout, typography, video, motion, and design patterns.</li>' +
'	<li>Multidisciplinary approach, equally comfortable designing static and moving images.</li>' +
'	<li>Strong verbal and creative writing skills.</li>' +
'	<li>Experience managing designers and workflow of creative assets.</li>' +
'	<li>Self-starter with a team mentality and an entrepreneurial mindset.</li>' +
'	<li>Knowledgeable about influential trends, ideas and people in entertainment.</li>' +
'	<li>Ability to manage multiple projects at one time to meet deadlines.</li>' +
'</ul>'
        },
        { // 10
          title: 'Video Editor',
          content: '<p><b>Responsibilities</b></p>' +
'<ul>' +
'	<li>Edit a high volume of in-house original videos, and create various versions of edits, optimized for different platforms that align with TikTok style guide.</li>' +
'	<li>Build video templates and graphic design work that adheres to TikTok brand style guidelines.</li>' +
'	<li>Work closely with external creative agencies to help guide concept ideation, production and editing.</li>' +
'	<li>Assist in planning, shooting and editing content to be used in paid advertising across digital media channels (Facebook, Snapchat, Twitter, YouTube, etc.).</li>' +
'	<li>Innovate new ways to repurpose and improve existing creative bank.</li>' +
'	<li>Excellent attention to detail and ability to proof-check your own work.</li>' +
'	<li>In addition to being a great editor, you must be an all around creative, with an eye for small details and a passion for big ideas.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Qualifications</b></p>' +
'<ul>' +
'	<li>Bachelor\'s degree or equivalent experience.</li>' +
'	<li>2+ years of professional experience in a similar role.</li>' +
'	<li>Native-speaker level in German language and advanced fluency in English is required.</li>' +
'	<li>Successful track record of producing and editing content for performance marketing ads on digital media channels.</li>' +
'	<li>Ability to overlay music, dialogues, graphics and effects.</li>' +
'	<li>Highly creative, organised and able to work independently with little direction.</li>' +
'	<li>Great communication skills and a collaborative team player in a cross-cultural and cross-functional environment.</li>' +
'	<li>Passionate about social media and short video products.</li>' +
'	<li>Understanding of today\'s media landscape and how people engage with advertising.</li>' +
'</ul>' +
'<br/><br/>' +
'<p><b>Even Better If</b></p>' +
'<ul>' +
'	<li>Ability to shoot and produce content with a digital camera.</li>' +
'	<li>Comfortable working in a fast paced, ever-changing production environment.</li>' +
'	<li>Understanding of creative, design and marketing processes.</li>' +
'	<li>Experience distilling disparate sources of information into actionable inputs.</li>' +
'</ul>'
        }
      ],
      job: {}
    };
  },
  mounted() {
    const index = this.$route.params.index;
    if (index >= 0 && index < this.jobs.length) {
      this.job = this.jobs[index];
    }
  }
};
</script>
